#header
  display: flex
  justify-content: center

  .img-wrapper
    width: 95%
    transform: skew(-30deg, 0)
    overflow: hidden

    img
      width: 200%
      object-fit: cover
      transform: skew(30deg, 0)
      transform-origin: bottom left

  .content
    text-align: center
    display: flex
    flex-direction: column
    justify-content: center
    height: 100%

    button
      margin: 1em