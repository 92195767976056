#about
  // margin-top: 1.5em
  .circle
    border-radius: 50%
  // margin: 0 auto
  #about-circled-image
    // background: url("https://fakeimg.pl/1024/")
    background: url("../images/000.jpg")
    background-size: cover
    background-position: center
    width: 75%

    &:before
      content: ''
      display: block
      padding-bottom: 100%

  .info-blocks-wrapper
    min-height: 100%

    .info-block-text
      display: inline-block

    svg
      flex-basis: auto
      flex-shrink: 0